<template>
  <div class="history_css" id="open-mini-program" @click="goWx_">小程序 Go</div>
</template>
<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import wx from "weixin-js-sdk";

export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.config();
  },
  methods: {
    goWx_() {
      console.log("a");
      wx.miniProgram.navigateTo({
        appId: "wxee27fd4812d5a21e", // 要打开的小程序的 appId
        path: "pages/my/help/help?param=value", // 小程序内要打开的页面路径和参数
        success(res) {
          console.log("成功打开小程序", res);
        },
        fail(err) {
          console.error("打开小程序失败", err);
        },
      });
    },
    config() {
      const timestamp = Math.floor(Date.now() / 1000);
      console.log(timestamp)
      wx.config({
        debug: false,
        appId: "wxee27fd4812d5a21e",
        timestamp: timestamp,
        nonceStr: "your-nonce-str",
        signature: "your-signature",
        jsApiList: ["navigateToMiniProgram"],
      });

      wx.ready(() => {
        console.log("微信 SDK 已准备好");
      });

      wx.error((res) => {
        console.error("微信 SDK 配置错误", res);
      });
    },
  },
};
</script>

<style  lang="less" scoped>
.history_css {
  text-align: center;
  line-height: 500px;
}
</style>